// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/4b834f0a/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cabernet-js": () => import("/tmp/4b834f0a/src/pages/cabernet.js" /* webpackChunkName: "component---src-pages-cabernet-js" */),
  "component---src-pages-chateau-margaux-js": () => import("/tmp/4b834f0a/src/pages/chateau-margaux.js" /* webpackChunkName: "component---src-pages-chateau-margaux-js" */),
  "component---src-pages-closing-js": () => import("/tmp/4b834f0a/src/pages/closing.js" /* webpackChunkName: "component---src-pages-closing-js" */),
  "component---src-pages-events-js": () => import("/tmp/4b834f0a/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-global-js": () => import("/tmp/4b834f0a/src/pages/global.js" /* webpackChunkName: "component---src-pages-global-js" */),
  "component---src-pages-index-js": () => import("/tmp/4b834f0a/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jwdm-js": () => import("/tmp/4b834f0a/src/pages/jwdm.js" /* webpackChunkName: "component---src-pages-jwdm-js" */),
  "component---src-pages-marli-russell-js": () => import("/tmp/4b834f0a/src/pages/marli-russell.js" /* webpackChunkName: "component---src-pages-marli-russell-js" */),
  "component---src-pages-members-table-js": () => import("/tmp/4b834f0a/src/pages/members-table.js" /* webpackChunkName: "component---src-pages-members-table-js" */),
  "component---src-pages-opening-js": () => import("/tmp/4b834f0a/src/pages/opening.js" /* webpackChunkName: "component---src-pages-opening-js" */),
  "component---src-pages-release-js": () => import("/tmp/4b834f0a/src/pages/release.js" /* webpackChunkName: "component---src-pages-release-js" */),
  "component---src-pages-sustainability-js": () => import("/tmp/4b834f0a/src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-team-js": () => import("/tmp/4b834f0a/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-vineyard-js": () => import("/tmp/4b834f0a/src/pages/vineyard.js" /* webpackChunkName: "component---src-pages-vineyard-js" */),
  "component---src-pages-vintage-js": () => import("/tmp/4b834f0a/src/pages/vintage.js" /* webpackChunkName: "component---src-pages-vintage-js" */),
  "component---src-pages-visiting-js": () => import("/tmp/4b834f0a/src/pages/visiting.js" /* webpackChunkName: "component---src-pages-visiting-js" */)
}

